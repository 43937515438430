import React from "react"
import { CounterBarConfig } from "../config/config-data"
import "../styles/counterbar.css"


const CounterBar = () => {

  const [ Number, setNumber ] = React.useState()
  const [ Show, setShow ] = React.useState(false)

  React.useEffect(() => { 
    let isMounted = true;
    const BarInterval = setInterval(() => {
      if (isMounted) {
        setShow(true);
      };
      if (isMounted) {
        setNumber(Math.floor(Math.random() * (Math.floor(CounterBarConfig.maximum_viewer) - Math.ceil(CounterBarConfig.minimum_viewer) + 1) + Math.ceil(CounterBarConfig.minimum_viewer)));
      };
      const BarTimeout = setTimeout(() => {
        if (isMounted) {
          setShow(false);
        };
      }, CounterBarConfig.bar_show_time);
      return () => {
        clearTimeout(BarTimeout);
      };
    }, CounterBarConfig.bar_show_delay);
    return () => {
      clearInterval(BarInterval);
      setNumber({});
      isMounted = false;
    };
  }, [])

  return (
    <div id="couterbar_1662794597926" className={Show ? "Counter_Bar Counter_Bar__Show" : "Counter_Bar"}>
      <div id="couterbar_1662794599454" className="Normal_Text"><span className="Counter_Bar_Text">{Number}</span> poeple viewing this page</div>
    </div>
  );
}

export default CounterBar
